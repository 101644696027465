var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm.type == 1 ? _c('span', [_vm._v(_vm._s(_vm.$t("corporate-hibah")))]) : _vm._e()])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4 col-md-4 col-12"
  }, [_c('div', {
    staticClass: "sidebar sticky-bar p-4 rounded shadow bg-light"
  }, [_c('div', {
    staticClass: "widget mb-4 pb-4 border-bottom"
  }, [_c('div', {
    staticClass: "widget-search mt-4 mb-0",
    attrs: {
      "id": "jobkeywords"
    }
  }), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.trustPart >= 4 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'trust-onecent.part-d',
        query: {
          trust_id: _vm.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part-a")) + " ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("donor")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.trustPart >= 5 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'trust-onecent.part-e',
        query: {
          trust_id: _vm.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part-b")) + " ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("beneficiary")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.trustPart >= 6 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'trust-onecent.part-f.moveable',
        query: {
          trust_id: _vm.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part-c")) + " ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("account")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.trustPart >= 7 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'trust-onecent.part-g',
        query: {
          trust_id: _vm.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part-d")) + " ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("caretaker")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.trustPart >= 8 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'trust-onecent.part-h',
        query: {
          trust_id: _vm.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part-e")) + " ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("Pengesahan")])]), _vm.trust && _vm.trust.status == 2 && _vm.userRole == 'Staff' ? _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.trustPart >= 9 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'trust-onecent.part-i',
        query: {
          trust_id: _vm.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part-f")) + " ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("witness")))])]) : _vm._e(), _vm.amendmentId && _vm.amendment && _vm.amendment.status == 3 || _vm.trust && _vm.trust.status == 3 && _vm.trust.is_draft_accepted == 1 ? _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.trustPart >= 10 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'trust-onecent.part-signpenerima',
        query: {
          trust_id: _vm.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("profile.signature")) + " ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("recipient-signature")))])]) : _vm._e()], 1), _vm.userRole == 'Staff' && _vm.trust && _vm.trust.status == 2 ? _c('hr', {
    staticClass: "primary"
  }) : _vm._e(), _vm.userRole == 'Staff' && _vm.trust && _vm.trust.status == 2 || _vm.userRole == 'Staff' && this.amendment_status == 1 ? _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('button', {
    staticClass: "btn btn-success mr-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.approveHibah
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]), _c('button', {
    staticClass: "btn btn-danger ml-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.declineHibah
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")])]) : _vm._e(), _vm.trust && _vm.trust.status == 5 ? _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onNavigate
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("print-cert")) + " ")])], 1) : _vm._e()])]), _c('router-view')], 1)])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }